<script lang="ts" setup>
import useEngagementSettingsNavigation from '~/src/UserInterface/Engagement/composables/useEngagementSettingsNavigation'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const { getUser } = useUserState()
const user = getUser()
const { getOrganisation } = useOrganisationState()
const organisation = getOrganisation()
const { getCurrentEngagement, engagements, getCurrentPhase } = useEngagementState()
const currentEngagement = getCurrentEngagement()
const currentPhase = getCurrentPhase()
const { engagementSettingsCoaMappingsLocalePath } = useEngagementSettingsNavigation(organisation, currentEngagement)
</script>

<template>
  <div
    id="settings-coa-mapping"
    class="main-content d-flex flex-column"
  >
    <organisation-navbar-top
      :user
      :organisation
      :engagement="currentEngagement"
      :close-button-to="engagementSettingsCoaMappingsLocalePath"
    />

    <organisation-navbar-dropdown
      :user
      :organisation
      :current-engagement="currentEngagement"
      :engagements="engagements"
      :current-phase="currentPhase"
    />

    <div class="flex-grow-1">
      <slot />
    </div>

    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
